import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Container from '../layouts/container';

import styles from './404.module.scss';

const Page404 = ({ data }) => (
  <Container>
    <section className={styles.contentWrapper}>
      <div className={styles.imageContainer}>
        <Img fluid={data.file.childImageSharp.fluid} loading="eager" />
      </div>
      <section>
        <h1 className={styles.pageTitle}>Oops...</h1>
        <p className={styles.pageSubtitle}>
          We can&apos;t seem to find the page you&apos;re looking for, sorry!
        </p>
        <p className={styles.paragraph}>
          Click
          {' '}
          <Link className={styles.link} to="/">here</Link>
          {' '}
          to go back to the homepage.
        </p>
      </section>
    </section>
  </Container>
);

export const query = graphql`
  {
    file(relativePath: { eq: "lost.png" }) {
      childImageSharp {
        fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Page404;
